import {Card, CardContent, Grid} from "@mui/material";

const ItemAddPage = () => {
    return (
        <Card>
            <CardContent>
                <Grid container>
                    <Grid item md={12} lg={12} sm={12}>

                        <form>



                        </form>

                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ItemAddPage