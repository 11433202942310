

export const ENDPOINT_LOGIN = '/auth/login/';


//brands
export const ENDPOINT_BRANDS = '/brands/';
export const ENDPOINT_ADD_BRANDS = '/brands/';

//categories
export const ENDPOINT_CATEGORIES = '/categories/';
export const ENDPOINT_ADD_CATEGORY = '/categories/';