import { Card, CardContent, Grid, Modal } from "@mui/material";
import BrandAddForm from "./components/brand-add-form";
import BrandListComponent from "./components/brand-list";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getBrands } from "../../../redux/slice/brand-slice";
import './style.css';

const BrandsPage = () => {

    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getBrands(''));
    }, [])


    return (
        <Grid container spacing={1}>
            {/* <Modal open={open}>
                <BrandAddForm onCancel={() => setOpen(false)} />
            </Modal> */}
            <BrandAddForm onCancel={() => setOpen(false)} open={open} />

            {/*<Grid item md={6} lg={4} sm={6}>*/}
            {/*    <BrandAddForm/>*/}
            {/*</Grid>*/}
            {/*<Grid item md={6} lg={8} sm={6}>*/}
            {/*    <BrandListComponent/>*/}
            {/*</Grid>*/}
            <Grid item md={12} lg={12} sm={12}>
                <BrandListComponent />
            </Grid>
        </Grid>
    )
}

export default BrandsPage