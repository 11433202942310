import axios from 'axios';
import config from "../config/config";
import {ENDPOINT_ADD_BRANDS, ENDPOINT_ADD_CATEGORY, ENDPOINT_BRANDS, ENDPOINT_CATEGORIES, ENDPOINT_LOGIN} from "./end-points";

const BASE_URL: string = config.API_URL;

const api = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }
});

export const apiLogin: any = async (data: any) => await api.post(ENDPOINT_LOGIN, data);

//Brands
export const apiBrands: any = async (filter:string) => await api.get(ENDPOINT_BRANDS+'?name='+filter);
export const apiAddBrand: any = async (data: any) => await api.post(ENDPOINT_ADD_BRANDS, data);

//Categories
export const apiCategories: any = async (filter:string) => await api.get(ENDPOINT_CATEGORIES+'?name='+filter);
export const apiAddCategory: any = async (data: any) => await api.post(ENDPOINT_ADD_CATEGORY, data);

axios.interceptors.request.use((request: any): any => {
    console.log('request interceptor', request);
    console.log('addBrand addBrand', request.headers);
}, (err) => {
    console.log('error is', err);
    return Promise.reject(err);
});


export default api;