import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Category } from "../../interfaces/interface-category";

const categoriesState = {
    isCategoriesLoading: true,
    categories: [] as Category[],
    categoriesErrors: {},
}

const categoriestate = {
    isCategoryLoading: true,
    category: {} as Category,
    categoryErrors: {} as Category,
}

const addCategoriesState = {
    isCategoryAdding: false,
    category: {} as Category,
    addCategoryError: {} as Category
}


export const initialState = {
    ...categoriesState,
    ...categoriestate,
    ...addCategoriesState
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        getCategories: (state, action?: PayloadAction<any>) => {
            console.log('getcategories action is', action);
            state.isCategoriesLoading = true;
        },
        setCategories: (state, action) => {
            state.isCategoriesLoading = false;
            state.categories = action.payload
        },
        failedCategories: (state, action?: PayloadAction<any>) => {
            state.isCategoriesLoading = false;
            state.categoriesErrors = action?.payload
        },
        addCategory: (state, action: PayloadAction<Category>) => {
            state.addCategoryError = {} as Category;
            state.isCategoryAdding = true;
        },
        setAddCategory: (state, action: PayloadAction<Category>) => {
            state.isCategoryAdding = false;
            state.categories.push(action.payload);
        },
        failedAddCategory: (state, action?: PayloadAction<any>) => {
            state.isCategoryAdding = false;
            state.addCategoryError = action?.payload
        }
    }
});

export const {
    getCategories, setCategories, failedCategories,
    addCategory, setAddCategory, failedAddCategory
} = categorySlice.actions
export default categorySlice.reducer

