import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../interfaces/interface-user";

const errorState = {
    email: '',
    password: '',
}

const initialState = {
    isAuth: false,
    isLoading: false,
    // user as User: {}
    user: {},
    errors: { ...errorState }
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getLogin: (state, action: { type: string, payload: { email: string, password: string } }) => {
            state.isLoading = true;
            state.errors = { email: '', password: '' }
        },
        setLogin: (state, action) => {
            state.isLoading = false;
            state.isAuth = true;
            state.user = action.payload;
        },
        failedLogin: (state, action?: PayloadAction<any>) => {
            state.isLoading = false;
            state.errors = action?.payload
        }
    }
});

export const { getLogin, setLogin, failedLogin } = authSlice.actions;
export default authSlice.reducer;

