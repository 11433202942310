import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Outlet, useNavigate } from 'react-router-dom';
import * as Route from '../../routes/'
import { useDispatch } from 'react-redux';
import config from "../../config/config";
// import { getLogout } from '../redux/slice/user-slice';

const drawerWidth = 240;

const openedMixin = (theme: Theme)
    :
    CSSObject => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: '#1c1e28',
        color: 'white',
        overflowX: 'hidden',
    });

const closedMixin = (theme: Theme)
    :
    CSSObject => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        backgroundColor: '#1c1e28',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 1px)`,
        },
    });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: 'red',
        color: 'red',
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);


export default function AppLayout() {
    const listItem = ['Home', 'All Item', 'Add Item', 'Brands', 'Categories'];
    const listIcon = [<MailIcon sx={{ color: "white" }} />, <MailIcon sx={{ color: "white" }} />,
    <MailIcon sx={{ color: "white" }} />, <MailIcon sx={{ color: "white" }} />, <MailIcon sx={{ color: "white" }} />];

    const listRoute = [
        Route.ROUTE_DASHBOARD,
        Route.ROUTE_ITEM_ADD,
        Route.ROUTE_ITEMS,
        Route.ROUTE_BRANDS,
        Route.ROUTE_CATEGORIES,
    ];

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const navigate = useNavigate();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const dispatch = useDispatch();

    return (
        <Box sx={{ display: 'flex', backgroundColor: '#f2f5f9', height: '100vh' }}>
            <CssBaseline />

            <AppBar position="fixed" open={open} sx={{ backgroundColor: '#1c1e28', boxShadow: 0 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        PHASE - {config.APP_ENV}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={{ backgroundColor: '#1c1e28' }}>
                <DrawerHeader sx={{ backgroundColor: '#1c1e28' }}>
                    <Typography variant="h6" noWrap component="div">
                        {config.APP_NAME}
                    </Typography>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon sx={{ color: 'white' }} />
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {listItem.map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton onClick={() => navigate(listRoute[index])}>
                                <ListItemIcon>
                                    {listIcon[index]}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <Divider />
                <List>
                    {/*<ListItem key='Logout' disablePadding onClick={()=>dispatch(getLogout())}>*/}
                    <ListItem key='Logout' disablePadding onClick={() => navigate('/login')}>
                        <ListItemButton>
                            <ListItemIcon>
                                <InboxIcon sx={{ color: "white" }} />
                            </ListItemIcon>
                            <ListItemText primary='Logout' />
                        </ListItemButton>
                    </ListItem>
                    {['Settings', 'About Us'].map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon sx={{ color: "white" }} /> :
                                        <MailIcon sx={{ color: "white" }}

                                        />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box>
    );
}