import { useForm, Controller } from "react-hook-form";
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Select, TextField, Modal } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import IconButton from "@mui/material/IconButton";
import '../style.css'
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useEffect } from "react";
import { addCategory } from "../../../../redux/slice/category-slice";
import { categorySchema } from "../../../../validators/category-validator";


const CategoryAddForm = ({ onCancel, open }: any) => {

    const { addCategoryError, categories } = useSelector((state: RootState) => state.category);

    useEffect(() => {
        reset();
    }, [categories]);

    const dispatch = useDispatch();

    const defaultValues = {
        name: '',
        short_name: '',
        type: 'pharmacy',
        meta_title: '',
        meta_description: ''
    }

    const { control, reset, formState, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(categorySchema)
    });

    const { errors, dirtyFields, isValid } = formState;


    const onSubmit = (data: any) => {
        dispatch(addCategory(data));
        // reset();
    }


    return (
        <Modal open={open}>

            <Card sx={{ boxShadow: 0 }} className={"model"}>
                <CardHeader title="Add Category" />
                <IconButton className="btnIcon" onClick={onCancel}><Cancel /></IconButton>
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Grid container>
                            <Grid item lg={6} sm={12} sx={{ pr: 1 }} md={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ mb: 2 }}
                                            label="Name"
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.name || !!addCategoryError?.name}
                                            helperText={errors?.name?.message || addCategoryError?.name}
                                            required
                                            fullWidth
                                            autoFocus
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={6} sm={12} sx={{ pr: 1 }} md={6}>
                                <Controller
                                    name="short_name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ mb: 2 }}
                                            label="Short Name"
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.short_name || !!addCategoryError?.short_name}
                                            helperText={errors?.short_name?.message || addCategoryError?.short_name}
                                            required
                                            fullWidth
                                            autoFocus
                                        />
                                    )}
                                />
                            </Grid>

                            <Controller
                                name="meta_title"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Meta Title"
                                        type="text"
                                        variant="outlined"
                                        error={!!errors.meta_title || !!addCategoryError?.meta_title}
                                        helperText={errors?.meta_title?.message || addCategoryError?.meta_title}
                                        fullWidth
                                        autoFocus
                                    />
                                )}
                            />
                            <Controller
                                name="meta_description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Meta Description"
                                        type="text"
                                        variant="outlined"
                                        rows={4}
                                        error={!!errors.meta_description || !!addCategoryError?.meta_description}
                                        helperText={errors?.meta_description?.message || addCategoryError?.meta_description}
                                        multiline
                                        fullWidth
                                        autoFocus
                                    />
                                )}
                            />

                            <Button
                                sx={{ boxShadow: 0 }}
                                type="submit"
                                variant="contained"
                                aria-label="LOG IN"
                                className="btn"
                                color="primary"
                                // disabled={_.isEmpty(dirtyFields) || !isValid}
                                disabled={!isValid}
                                fullWidth
                            >
                                Add Category
                            </Button>

                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );

}

export default CategoryAddForm;