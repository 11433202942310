import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authSlice from "../slice/auth-slice";
import createSagaMiddleware from "@redux-saga/core";
import rootSaga from "../saga/root-saga";
import brandSlice from "../slice/brand-slice";
import categorySlice from "../slice/category-slice";

const saga = createSagaMiddleware()

const store = configureStore({
    reducer: {
        auth: authSlice,
        brand:brandSlice,
        category:categorySlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(saga)
});

saga.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;