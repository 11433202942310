import { takeLatest, put, call } from "redux-saga/effects";
import { failedAddCategory, failedCategories, setAddCategory, setCategories } from "../slice/category-slice";
import { apiAddCategory, apiCategories } from "../../http";
import { ResponseCategories } from "../../response/response-categories";
import { ResponseDefault } from "../../interfaces/interface-response";
import { Category } from "../../interfaces/interface-category";
import { ResponseError } from "../../response/response-error";
import Toast from "../../utils/toast";


function* workCategories({ payload }: any) {
    try {
        console.log('workCategories pauload is', payload);
        const res: ResponseDefault = yield call(apiCategories, payload)
        const response: ResponseCategories = res.data;
        if (res.status === 200) {
            yield put(setCategories(response.results));
        } else {
            yield put(failedCategories(response));
        }
    }
    catch (e) {
        put(failedCategories)
    }
}

function* workAddCategory({ payload }: any) {

    try {
        const res: ResponseDefault = yield call(apiAddCategory, payload);
        const response: Category = res.data;
        if (res.status === 201) {
            yield put(setAddCategory(response))
            Toast.showSuccessToast("Category Added Successfully");
        } else {
            Toast.showErrorToast("Failed to add this Category");
            yield put(failedAddCategory(response));
        }
    }
    catch (e: any) {
        const err = e as ResponseError
        Toast.showErrorToast("Failed to add this Category");
        yield put(failedAddCategory(err?.response?.data));
    }

}

export function* watchCategories() {
    yield takeLatest('category/getCategories', workCategories);
}

export function* watchAddCategory() {
    yield takeLatest('category/addCategory', workAddCategory);
}