import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import { Brand } from "../../../../interfaces/interface-brand";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { getBrands } from "../../../../redux/slice/brand-slice";
import config from "../../../../config/config";

const BrandListComponent = () => {

    const { brands, isBrandsLoading } = useSelector((state: RootState) => state.brand);

    const dispatch = useDispatch();

    const buildTableHead = () => {
        return <TableHead>
            <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Short Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
        </TableHead>
    }

    const buildTableRow = (brand: Brand, index: number) => {
        return <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell style={{ padding: '0px' }}>
                <Avatar src={config.BACKEND_IMAGE_URL + brand.logo?.thumbnail} />
            </TableCell>
            <TableCell>{brand.name}</TableCell>
            <TableCell>{brand.short_name}</TableCell>
            <TableCell>{brand.type}</TableCell>
            <TableCell>Action</TableCell>
        </TableRow>
    }


    const handleSearchFormChange = (e: any) => {
        console.log('e.traget.value is', e.target.value);
        dispatch(getBrands(e.target.value));
    }

    return (

        <Card sx={{ boxShadow: 0 }}>
            <CardContent>
                <TextField
                    onChange={handleSearchFormChange}
                    label={"Search Brands"}
                    fullWidth
                />
                <Table>
                    {buildTableHead()}
                    <TableBody>
                        {
                            brands.map((brand: Brand, index: number) => {
                                return buildTableRow(brand, index);
                            })
                        }
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );

}

export default BrandListComponent;