import { useForm, Controller } from "react-hook-form";
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, Select, TextField, Modal } from "@mui/material";
import { brandSchema } from "../../../../validators/brand-validator";
import { yupResolver } from "@hookform/resolvers/yup";
import IconButton from "@mui/material/IconButton";
import '../style.css'
import { Cancel } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addBrand } from '../../../../redux/slice/brand-slice'
import { RootState } from "../../../../redux/store";
import {useEffect} from "react";


const BrandAddForm = ({ onCancel, open }: any) => {

    const { addBrandError,brands } = useSelector((state: RootState) => state.brand);

    useEffect(()=>{
        reset();
    },[brands]);

    const dispatch = useDispatch();

    const defaultValues = {
        name: '',
        short_name: '',
        type: 'pharmacy',
        meta_title: '',
        meta_description: ''
    }

    const { control, reset, formState, handleSubmit } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(brandSchema)
    });

    const { errors, dirtyFields, isValid } = formState;


    const onSubmit = (data: any) => {
        dispatch(addBrand(data));
        // reset();
    }


    return (
        <Modal open={open}>

            <Card sx={{ boxShadow: 0 }} className={"model"}>
                <CardHeader title="Add Brand" />
                <IconButton className="btnIcon" onClick={onCancel}><Cancel /></IconButton>
                <CardContent>
                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Grid container>
                            <Grid item lg={6} sm={12} sx={{ pr: 1 }} md={6}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ mb: 2 }}
                                            label="Name"
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.name || !!addBrandError?.name}
                                            helperText={errors?.name?.message || addBrandError?.name}
                                            required
                                            fullWidth
                                            autoFocus
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item lg={3} sm={12} sx={{ pr: 1 }} md={6}>
                                <Controller
                                    name="short_name"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ mb: 2 }}
                                            label="Short Name"
                                            type="text"
                                            variant="outlined"
                                            error={!!errors.short_name || !!addBrandError?.short_name}
                                            helperText={errors?.short_name?.message || addBrandError?.short_name}
                                            required
                                            fullWidth
                                            autoFocus
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item sm={12} lg={3} md={6}>
                                <Controller
                                    name="type"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            sx={{ mb: 2 }}
                                            label="Type"
                                            variant="outlined"
                                            error={!!errors.type}
                                            required
                                            fullWidth
                                            autoFocus
                                        >
                                            <MenuItem value={"pharmacy"}>Pharmacy</MenuItem>
                                            <MenuItem value={"ayurvedic"}>Ayurvedic</MenuItem>
                                        </Select>
                                    )}
                                />
                            </Grid>

                            {/*<Grid lg={6} md={6}>*/}
                            {/*    <Controller*/}
                            {/*        name="name"*/}
                            {/*        control={control}*/}
                            {/*        render={({field}) => (*/}
                            {/*            <TextField*/}
                            {/*                {...field}*/}
                            {/*                sx={{mb: 2}}*/}
                            {/*                label="Image"*/}
                            {/*                type="text"*/}
                            {/*                variant="outlined"*/}
                            {/*                error={!!errors.name}*/}
                            {/*                helperText={errors?.name?.message}*/}
                            {/*                fullWidth*/}
                            {/*                autoFocus*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            <Controller
                                name="meta_title"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Meta Title"
                                        type="text"
                                        variant="outlined"
                                        error={!!errors.meta_title || !!addBrandError?.meta_title}
                                        helperText={errors?.meta_title?.message || addBrandError?.meta_title}
                                        fullWidth
                                        autoFocus
                                    />
                                )}
                            />
                            <Controller
                                name="meta_description"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Meta Description"
                                        type="text"
                                        variant="outlined"
                                        rows={4}
                                        error={!!errors.meta_description || !!addBrandError?.meta_description}
                                        helperText={errors?.meta_description?.message || addBrandError?.meta_description}
                                        multiline
                                        fullWidth
                                        autoFocus
                                    />
                                )}
                            />

                            <Button
                                sx={{ boxShadow: 0 }}
                                type="submit"
                                variant="contained"
                                aria-label="LOG IN"
                                className="btn"
                                color="primary"
                                // disabled={_.isEmpty(dirtyFields) || !isValid}
                                disabled={!isValid}
                                fullWidth
                            >
                                Add Brand
                            </Button>

                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Modal>
    );

}

export default BrandAddForm;