import { takeLatest, put, call } from "redux-saga/effects";
import { failedAddBrand, failedBrands, setAddBrand, setBrands } from "../slice/brand-slice";
import { apiAddBrand, apiBrands } from "../../http";
import { ResponseBrands } from "../../response/response-brands";
import { ResponseDefault } from "../../interfaces/interface-response";
import { Brand } from "../../interfaces/interface-brand";
import { ResponseError } from "../../response/response-error";
import Toast from "../../utils/toast";


function* workBrands({ payload }: any) {
    try {
        console.log('workBrands pauload is', payload);
        const res: ResponseDefault = yield call(apiBrands, payload)
        const response: ResponseBrands = res.data;
        if (res.status === 200) {
            yield put(setBrands(response.results));
        } else {
            yield put(failedBrands(response));
            console.log("🚀 ~ file: brand-saga.ts ~ line 20 ~ function*workBrands ~ response", response)
        }
    }
    catch (e) {
        put(failedBrands)
    }
}

function* workAddBrand({ payload }: any) {

    try {
        const res: ResponseDefault = yield call(apiAddBrand, payload);
        const response: Brand = res.data;
        if (res.status === 201) {
            yield put(setAddBrand(response))
            Toast.showSuccessToast("Brand Added Successfully");
        } else {
            Toast.showErrorToast("Failed to add this Brand");
            yield put(failedAddBrand(response));
        }
    }
    catch (e: any) {
        const err = e as ResponseError
        Toast.showErrorToast("Failed to add this Brand");
        yield put(failedAddBrand(err?.response?.data));
    }

}

export function* watchBrands() {
    yield takeLatest('brand/getBrands', workBrands);
}

export function* watchAddBrand() {
    yield takeLatest('brand/addBrand', workAddBrand);
}