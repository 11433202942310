import { Card, CardContent, Grid, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCategories } from "../../../redux/slice/category-slice";
import CategoryAddForm from "./components/category-add-form";
import CategoryListComponent from "./components/cateogry-list";
import './style.css';

const CategoriesPage = () => {

    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategories(''));
    }, [])


    return (
        <Grid container spacing={1}>

            <CategoryAddForm onCancel={() => setOpen(false)} open={open} />

            <Grid item md={12} lg={12} sm={12}>
                <CategoryListComponent />
            </Grid>
        </Grid>
    )
}

export default CategoriesPage