const env = process.env.REACT_APP_ENV || 'production';

const localhost = {
    APP_ENV: 'Localhost',
    APP_NAME: 'PharmaFist',
    APP_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:8000/api/v1',
    BACKEND_IMAGE_URL: 'http://localhost:8000/',
    BACKEND_URL: 'http://localhost:8000'
}

const development = {
    APP_ENV: 'Development',
    APP_NAME: 'PharmaFist',
    APP_URL: 'http://localhost:3000',
    API_URL: 'http://localhost:8000/api/v1',
    BACKEND_IMAGE_URL: 'http://localhost:8000/',
    BACKEND_URL: 'http://localhost:8000'
}

const production = {
    APP_NAME: 'PharmaFist',
    APP_ENV: 'Production',
    APP_URL: 'https://medico-admin.socialcodia.com',
    API_URL: 'https://socialcodia.pythonanywhere.com/api/v1',
    BACKEND_IMAGE_URL: 'https://socialcodia.pythonanywhere.com/',
    BACKEND_URL: 'https://socialcodia.pythonanywhere.com/'
}

const environments: any = {
    localhost,
    development,
    production
}

export default environments[env]