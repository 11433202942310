import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Brand } from "../../interfaces/interface-brand";

const brandsState = {
    isBrandsLoading: true,
    brands: [] as Brand[],
    brandsErrors: {},
}

const brandState = {
    isBrandLoading: true,
    brand: {} as Brand,
    brandErrors: {} as Brand,
}

const addBrandsState = {
    isBrandAdding: false,
    brand: {} as Brand,
    addBrandError: {} as Brand
}


export const initialState = {
    ...brandsState,
    ...brandState,
    ...addBrandsState
}

const brandSlice = createSlice({
    name: 'brand',
    initialState,
    reducers: {
        getBrands: (state,action?:PayloadAction<any>) => {
            console.log('getBrands action is',action);
            state.isBrandsLoading = true;
        },
        setBrands: (state, action) => {
            state.isBrandsLoading = false;
            state.brands = action.payload
        },
        failedBrands: (state, action?: PayloadAction<any>) => {
            state.isBrandsLoading = false;
            state.brandsErrors = action?.payload
        },
        addBrand: (state, action: PayloadAction<Brand>) => {
            state.addBrandError = {} as Brand;
            state.isBrandAdding = true;
        },
        setAddBrand: (state, action: PayloadAction<Brand>) => {
            state.isBrandAdding = false;
            // state.brands = { ...state.brands, ...action.payload }
            state.brands.push(action.payload);
        },
        failedAddBrand: (state, action?: PayloadAction<any>) => {
            state.isBrandAdding = false;
            state.addBrandError = action?.payload
        }
    }
});

export const {
    getBrands, setBrands, failedBrands,
    addBrand, setAddBrand, failedAddBrand
} = brandSlice.actions
export default brandSlice.reducer

