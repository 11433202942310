import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { Category } from "../../../../interfaces/interface-category";
import { getCategories } from "../../../../redux/slice/category-slice";
import { Delete, Edit } from "@mui/icons-material";
import { Box } from "@mui/system";
import config from "../../../../config/config";

const CategoryListComponent = () => {

    const { categories, isCategoriesLoading } = useSelector((state: RootState) => state.category);

    const dispatch = useDispatch();

    const buildTableHead = () => {
        return <TableHead>
            <TableRow>
                <TableCell>Sr.No</TableCell>
                <TableCell>Logo</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Short Name</TableCell>
                <TableCell>Meta Title</TableCell>
                <TableCell>Meta Description</TableCell>
                <TableCell>Action</TableCell>
            </TableRow>
        </TableHead>
    }

    const buildTableRow = (category: Category, index: number) => {

        return <TableRow key={index}>
            <TableCell>{index + 1}</TableCell>
            <TableCell style={{ padding: '0px' }}>
                <Avatar src={config.BACKEND_IMAGE_URL + category.icon?.thumbnail} />
            </TableCell>
            <TableCell>{category.name}</TableCell>
            <TableCell>{category.short_name}</TableCell>
            <TableCell>{category.meta_title ?? 'Need to separate the administration api to show the meta title'}</TableCell>
            <TableCell>{category.meta_description ?? 'Need to separate the administration api to show the meta description'}</TableCell>
            <TableCell>
                <IconButton size="small" className="editButton"><Edit /></IconButton>
                <IconButton size="small" className="deleteButton"><Delete /></IconButton>
            </TableCell>
        </TableRow>
    }

    const handleSearchFormChange = (e: any) => {
        console.log('e.traget.value is', e.target.value);
        dispatch(getCategories(e.target.value));
    }

    return (

        <Card sx={{ boxShadow: 0 }}>
            <CardContent>
                <TextField
                    onChange={handleSearchFormChange}
                    label={"Search Categories"}
                    fullWidth
                />
                <Table>
                    {buildTableHead()}
                    <TableBody>
                        {
                            categories.map((category: Category, index: number) => {
                                return buildTableRow(category, index);
                            })
                        }
                    </TableBody>

                </Table>
            </CardContent>
        </Card>
    );

}

export default CategoryListComponent;