import { Routes, Route } from 'react-router-dom';
import LoginPage from "../page/auth/login";
import HomePage from "../page/home/home-page";
import AppLayout from "../components/layout/app-layout";
import ItemAddPage from "../page/item/add/item-add-page";
import BrandsPage from "../page/brand/all/brands-page";
import CategoriesPage from '../page/category/all/category-page';


export const ROUTE_LOGIN = "/login";
export const ROUTE_DASHBOARD = "/dashboard";
export const ROUTE_HOME = "/home";

//Items
export const ROUTE_ITEMS = "/items";
export const ROUTE_ITEM_ADD = "/item/add";

//Brands
export const ROUTE_BRANDS = "/brands";

//Categories
export const ROUTE_CATEGORIES = "/categories";
export const ROUTE_CATEGORY_ADD = "/category/add";




const MyRoute = () => {

    return (
        <Routes>
            <Route path={ROUTE_LOGIN} element={<LoginPage />} />
            <Route path={"/"} element={<AppLayout />}>
                <Route path={ROUTE_HOME} element={<HomePage />} />
                <Route path={ROUTE_ITEMS} element={<ItemAddPage />} />
                <Route path={ROUTE_ITEM_ADD} element={<ItemAddPage />} />

                {/* Brands */}
                <Route path={ROUTE_BRANDS} element={<BrandsPage />} />

                {/* Category */}
                <Route path={ROUTE_CATEGORIES} element={<CategoriesPage />} />
            </Route>
        </Routes>
    )

}

export default MyRoute