import { Button, Card, CardContent, Grid, Paper, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { loginSchema } from "../../../validators/auth-validator";
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import './style.css';
import {useDispatch, useSelector} from "react-redux";
import { getLogin } from "../../../redux/slice/auth-slice";
import {RootState} from "../../../redux/store";
import {useState} from "react";

const LoginPage = () => {

    const dispatch = useDispatch();
    const {errors:apiErrors,isLoading} = useSelector((state:RootState)=> state.auth);
    const [showError,setShowError] = useState(false);

    const initialState = {
        email: 'socialcodia@gmail.com',
        password: 'socialcodia'
    }

    const { control, reset, handleSubmit, formState } = useForm({
        mode: 'onChange',
        defaultValues: initialState,
        resolver: yupResolver(loginSchema),

    });
    console.log(control);

    const { isValid, dirtyFields, errors } = formState;

    console.log(isValid)
    console.log(errors)


    const onSubmit = (data: any) => {
        setShowError(true);
        console.log(data)
        dispatch(getLogin(data));
        // reset();
    }


    return (
        <Grid container className="container"
              direction="row"
              justifyContent="center"
              alignItems="center">
            <Grid item sm={2} md={3} lg={4}>
            </Grid>
            <Grid item sm={8} md={6} lg={4} className="card-wrapper">
                <Card className="card">
                    <CardContent>
                        <Typography variant="h4" className="title">Sing in</Typography>
                        <p>Sign in into your account to continue...</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Email"
                                        autoFocus
                                        type="email"
                                        disabled={isLoading}
                                        error={!!errors.email || !!apiErrors.email}
                                        helperText={errors?.email?.message || apiErrors?.email}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <Controller
                                name="password"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ mb: 2 }}
                                        label="Password"
                                        disabled={isLoading}
                                        type="password"
                                        error={!!errors.password || !!apiErrors.password}
                                        helperText={errors?.password?.message || apiErrors?.password}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                )}
                            />

                            <Button
                                sx={{ boxShadow: 0 }}
                                type="submit"
                                variant="contained"
                                aria-label="LOG IN"
                                className="btn"
                                color="primary"
                                // disabled={_.isEmpty(dirtyFields) || !isValid}
                                disabled={!isValid || isLoading}
                                fullWidth
                            >
                                Sign in
                            </Button>

                        </form>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item sm={2} md={3} lg={4}>
            </Grid>
        </Grid>
    )
}

export default LoginPage