import { all, fork } from 'redux-saga/effects';

import * as authSaga from '../saga/auth-saga';
import * as brandSaga from '../saga/brand-saga';
import * as categorySaga from '../saga/category-saga';


function* rootSaga() {
    yield all([
        ...Object.values(authSaga),
        ...Object.values(brandSaga),
        ...Object.values(categorySaga),
    ].map(fork));
}

export default rootSaga
